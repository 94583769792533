import React from "react"
// import { useStaticQuery, graphql } from "gatsby"
// import Img from "gatsby-image"

import EarlyAccessLandingPage from "../components/early-access-landing-page"
// import campaignBuilderGIF from "../gifs/campaign-builder.gif"

const Heading = () => (
  <span>
    Get <span className="has-background-warning px-1">free</span> custom postcard design
  </span>
)

// const Subheading = () => (
//   <span>
//     Join our Early Access to get <span class="has-text-weight-semibold">20% off </span> 
//     plus <span class="has-text-weight-semibold">free custom design</span> when you send 2000+ postcards
//   </span>
// )

// const Media = () => {
//   const data = useStaticQuery(graphql`
//     query {
//       campaignBuilderDesktopImage: file(relativePath: { eq: "campaign-builder-desktop.png" }) {
//         childImageSharp {
//           fluid(maxWidth: 800) {
//             ...GatsbyImageSharpFluid
//           }
//         }
//       }
//     }
//   `)

//   return (
//     <>
//       <img src={campaignBuilderGIF} alt="Campaign Builder" />
//       {/* <Img fluid={data.campaignBuilderDesktopImage.childImageSharp.fluid} /> */}
//     </>
//   )
// }

const EarlyAccessSpecialOfferPage = () => {

  return (
    <EarlyAccessLandingPage
      title="Free Postcard Design"
      url="early-access-free-postcard-design"
      Heading={Heading}
      // Subheading={Subheading}
      // Media={Media}
      cta1="Get My Free Postcard Design"
    />
)}

export default EarlyAccessSpecialOfferPage